import { useRouter }             from "next/router";
import { useCallback, useState } from "react";

import { storeEvent }       from "_analytics/index";
import { useGetDeviceType } from "_common/hooks/global";
import { isPageRoute }      from "_common/utils";
import Icon                 from "_components/atoms/icon";
import Typography           from "_components/atoms/typography";

import useStyles from "./styles";

const Header = ( { title = "", titleClass = "", onClickViewMore } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );
	const router      = useRouter ();

	const [ isHovering, setIsHovering ] = useState ( false );
	const deviceType                    = useGetDeviceType ();

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	const mouseEnterHandler = useCallback ( () => {
		if ( deviceType !== "desktop" ) return;

		if ( checkPageRoute ( "home" ) )
			storeEvent ( "View_more", `Home_${ title }` );

		if ( checkPageRoute ( "live" ) )
			storeEvent ( "View_more", `live_${ title }` );
		setIsHovering ( true );
	}, [] );

	const onMouseLeaveHandler = useCallback ( () => {
		if ( deviceType !== "desktop" ) return;

		setIsHovering ( false );
	}, [] );

	return (
		<div
			className    = { classes.header }
			onClick      = { onClickViewMore }
			onMouseEnter = { mouseEnterHandler }
			onMouseLeave = { onMouseLeaveHandler }
		>
			<Typography
				className = { `${ classes.title } ${ titleClass }` }
				title     = { title }
			/>

			<div
				className = { classes.iconContainer }
			>
				<div className = "viewMoreContainer">
					<Typography
						className = { classes.viewMore }
						size      = { "sm" }
						title     = { "View More" }
					/>

					{/* {
						title === "Related Reels" ? null :
							( */}
					<Icon
						className = { classes.icon }
						name      = { ( isHovering || deviceType === "tablet" || deviceType === "mobile" ) ? "arrowForwardBlue" : "arrowForward" }
					/>

					{/* )
					} */}
				</div>
			</div>
		</div>
	);
};

export default Header;
