import React from "react";

import { useGetDeviceType }        from "_common/hooks/global";
import { getVideoCardCountPerRow } from "_common/utils";
import VideoCard                   from "_components/organisms/videoCardList/videoCard";

import Ads       from "./ads";
import useStyles from "./styles";

const component = ( {
	list,
	containerClass,
	from,
	activeTab,
	startingComponent,
	isWithoutAnimation,
	hideMenuOptions,
	fromRecommendations,
	cardType
} ) => {

	const { classes }      = useStyles ( { activeTab, cardType, from }, { name: "videoCardList" } );
	const cardsCountPerRow = getVideoCardCountPerRow ();
	const deviceType       = useGetDeviceType ();
	const isMobile         = deviceType === "mobile";

	return (
		<div className = { containerClass }>
			<div className = { classes.videoCardContainer }>
				{typeof startingComponent === "function" ? startingComponent () : null}

				{
					list.map ( ( item, idx ) => {
						const rightMostCard =   ( idx + 1 ) % ( cardsCountPerRow ) === 0;

						return (
							<React.Fragment key = { item.id }>
								<Ads cardType = { cardType }
									idx          = { idx }
								/>

								<VideoCard
									key                 = { item.id }
									activeTab           = { activeTab }
									cardType            = { cardType }
									data                = { item }
									from                = { from }
									fromRecommendations = { fromRecommendations }
									hideMenuOptions     = { hideMenuOptions }
									isMobile            = { isMobile }
									isWithoutAnimation  = { isWithoutAnimation }
									leftMostCard        = { idx % cardsCountPerRow === 0 }
									rightMostCard       = { rightMostCard }
								/>

							</React.Fragment>
						);
					} )
				}

			</div>

		</div>
	);
};

export default component;
