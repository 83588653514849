import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props, u ) => {

	return {
		mobileFeedContainer: {
			paddingBottom: "10rem"
		},
		videoCardContainer: {
			display             : "grid",
			gridTemplateColumns : "repeat(3, minmax( 0, 1fr ))",
			gridGap             : "3rem 1.6rem",

			[u.largerThan ( "xs" )]: {
				gridGap             : props.from === "searchReelTab" || props.from === "profileReelTab" ? "0.3rem" : props.from?.includes ( "reel" ) || props.from?.includes ( "Reel" ) ? "1.6rem" : "0",
				gridTemplateColumns : props.cardType === "reel"
					? props.from === "searchReelTab" || props.from === "profileReelTab"
						? "repeat(3, minmax( 0, 1fr ))"
						: "repeat(2, minmax( 0, 1fr ))"
					: "repeat(1, minmax( 0, 1fr ))"
			},

			[u.largerThan ( "sm" )]: {
				gridTemplateColumns: props.cardType === "reel"
					? "repeat(4, minmax( 0, 1fr )) !important"
					: "repeat(2,minmax( 0, 1fr )) !important",
				gridGap: "3rem 1.6rem"
			},

			[u.largerThan ( "md" )]: {
				gridTemplateColumns: props.cardType === "reel"
					? "repeat(4, minmax( 0, 1fr )) !important"
					: "repeat(3,minmax( 0, 1fr )) !important",
				gridGap: "3rem 1.6rem"
			},

			[u.largerThan ( "lg" )]: {
				gridTemplateColumns: props.cardType === "reel"
					? "repeat(6, minmax( 0, 1fr )) !important"
					: "repeat(4,minmax( 0, 1fr )) !important"
			},

			[u.largerThan ( "xl" )]: {
				gridTemplateColumns: props.cardType === "reel"
					? "repeat(6, minmax( 0, 1fr )) !important"
					: "repeat(5,minmax( 0, 1fr )) !important"
			},

			[u.largerThan ( "xxxl" )]: {
				gridTemplateColumns: "repeat(6, minmax( 0, 1fr )) !important"
			}

		},
		divider: {
			height     : "5px",
			background : theme.other.colors.border.primary
		},
		reelContainer: {
			padding: "2rem"
		}
	};
} );
