import { createUrl }   from "_common/utils/seo";
import { ShopBaseURL } from "_config/index";

export const getShopProductLink = ( { productId } ) => {
	const productURL = `${ ShopBaseURL }/product/${ productId }`;
	const _url       = new URL ( productURL );
	const finalURL   = createUrl ( _url, { source: "home_page", medium: "web", campaign: productId } );

	return finalURL;
};

export const getShopLink = overrideParams => {
	const _url = new URL ( ShopBaseURL );

	const _params = overrideParams || { source: "side_nav", medium: "web", campaign: "organic" };

	Object.entries ( _params ).forEach ( ( [ key, value ] ) => {
		_url.searchParams.append ( key, value );
	} );

	return _url.href;
};
