import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => {

	return {
		container: {
			cursor: "pointer"
		},
		name: {
			fontSize   : "1.5rem",
			fontWeight : "500",
			// marginBottom : "0.5rem",

			[rooterMediaQuery.mobile]: {
				textAlign: "center"
			}
		},
		image: {
			height       : "15rem",
			width        : "15rem",
			borderRadius : "1rem",
			aspectRatio  : 1,

			[rooterMediaQuery.tablet]: {
				height : "14rem",
				width  : "14rem"
			},

			[rooterMediaQuery.mobile]: {
				height : "7.5rem",
				width  : "7.5rem"
			}

		},
		ellipse: {
			width           : "4px",
			height          : "4px",
			margin          : "0rem 0.7rem",
			borderRadius    : "50%",
			backgroundColor : "#757575"
		},
		gameInfo: {
			display    : "flex",
			alignItems : "center",

			"& p": {
				fontSize: "1.25rem"
			}
		}

	};
} );

