import { useRef }     from "react";
import { Transition } from "react-transition-group";

const transitionStyles = {
	entering : { opacity: 1 },
	entered  : { opacity: 1 },
	exiting  : { opacity: 0 },
	exited   : { opacity: 0 }
};

const FadeInOut = ( { children, className, inProp = false, timeout = 300, ...rest } ) => {
	const nodeRef      = useRef ( null );
	const defaultStyle = {
		transition : `opacity ${ timeout }ms ease-in-out`,
		opacity    : 0
	};

	return (
		<Transition
			in      = { inProp }
			nodeRef = { nodeRef }
			timeout = { timeout }
			{ ...rest }
		>
			{state => (
				<div
					ref       = { nodeRef }
					className = { `${ className } fade fade-${ state }` }
					style     = { {
						...defaultStyle,
						...transitionStyles[state]
					} }
				>
					{children}
				</div>
			)}
		</Transition>
	);
};

export default FadeInOut;
