import { atom, useAtomValue, useSetAtom } from "jotai";

export const reportIssueModalAtom = atom ( false );
export const selectedOrderAtom = atom ( null );
export const canWatchMoreProContent = atom ( true );
export const previousPathAtom = atom ();
export const finishedAdAtom = atom ( false );
export const adBlockerDetectedAtom = atom ( false );
export const adWatchStatusAtom = atom ( {
	opened   : false,
	finished : false,
	skipped  : false
} );
const onProSuccess = atom ( {
	function: () => {}
} );

export const useGetAdBlockerDetected = () => useAtomValue ( adBlockerDetectedAtom );
export const useSetAdBlockerDetected = () => useSetAtom ( adBlockerDetectedAtom );

export const useGetAdWatchStatus = () => useAtomValue ( adWatchStatusAtom );
export const useSetAdWatchStatus = () => useSetAtom ( adWatchStatusAtom );

export const useGetReportIssueModal = () => useAtomValue ( reportIssueModalAtom );
export const useSetReportIssueModal = () => useSetAtom ( reportIssueModalAtom );

export const useGetSelectedOrder = () => useAtomValue ( selectedOrderAtom );
export const useSetSelectedOrder = () => useSetAtom ( selectedOrderAtom );

export const useGetPreviousPath = () => useAtomValue ( previousPathAtom );
export const useSetPreviousPath = () => useSetAtom ( previousPathAtom );

export const useGetCanWatchMoreProContent = () => useAtomValue ( canWatchMoreProContent );
export const useSetCanWatchMoreProContent = () => useSetAtom ( canWatchMoreProContent );

export const useGetFinishedAd = () => useAtomValue ( finishedAdAtom );
export const useSetFinishedAd = () => useSetAtom ( finishedAdAtom );

export const useGetOnProSuccess = () => useAtomValue ( onProSuccess );
export const useSetOnProSuccess = () => useSetAtom ( onProSuccess );