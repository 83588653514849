import { useRouter } from "next/router";

export const useRemoveQueryParam = () => {
	const router = useRouter ();

	return ( param, type = "replace" ) => {
		const { query } = router;

		/* if ( Array.isArray ( param ) ) {
      param.forEach ( v => {
        delete query[v];
      } );
    } else  */
		delete query[param];

		router[type] ( { query: query }, undefined, { shallow: true } );
	};
};

export const useUpdateQueryParams = () => {
	const router = useRouter ();

	return ( { addParams = {}, removeParams = [], type = "push" } ) => {
		const { query } = router;

		// Remove specified query params
		if ( Array.isArray ( removeParams ) ) {
			removeParams.forEach ( param => {
				delete query[param];
			} );
		}

		// Add new query params
		const updatedQuery = { ...query, ...addParams };

		router[type] ( { query: updatedQuery }, undefined, { shallow: true } );
	};
};

export const useAddQueryParam = () => {
	const router = useRouter ();

	return ( params, type = "push" ) => {
		router[type] ( { query: { ...router.query, ...params } },
			undefined,
			{ shallow: true }
		);
	};
};
