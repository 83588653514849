import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => ( {
	container: {
		margin: "4rem 2rem"
	},
	header: {
		display      : "flex",
		alignItems   : "center",
		marginBottom : "1.25rem",
		width        : "fit-content",

		[rooterMediaQuery.mobileAndTablet]: {
			width          : "100%",
			display        : "flex",
			justifyContent : "space-between"
		},

		[rooterMediaQuery.desktop]: {
			cursor    : "pointer",
			"&:hover" : {
				"& .viewMoreContainer": {
					transform: "translateX(0%)"
				}
			}
		}

	},
	viewMore: {
		color: theme.other.paletteNew.primaryDarkBlue
	},
	title: {
		// fontSize   : "1.75rem",
		fontWeight: "500"
	},
	icon: {
		height     : "1.5rem",
		width      : "1.5rem",
		marginLeft : "0.2rem"
	},
	iconContainer: {
		display    : props?.hideArrow ? "none" : "flex",
		alignItems : "center",
		cursor     : "pointer",
		marginLeft : "1rem",
		overflow   : "hidden",

		"& .viewMoreContainer": {
			transform  : "translateX(-80%)",
			transition : "all 0.25s ease-out",
			display    : "flex",
			alignItems : "center"
		},

		[rooterMediaQuery.mobileAndTablet]: {
			"& .viewMoreContainer": {
				transform     : "translateX(0%)",
				"& .viewMore" : {
					fontSize : "1.5rem",
					color    : theme.other.paletteNew.primaryDarkBlue
				}
			}
		}

	},
	videoCardContainer: {
		display             : "grid",
		gridTemplateColumns : "repeat(auto-fit, minmax(300px, 1fr))",
		gridGap             : "3rem 1.5rem"
	}

} ) );
