import { useRouter } from "next/router";

import Presentation from "./presentation";

export const GameSection = ( { title, gameList } ) => {
	const router = useRouter ();

	const onClickViewMore = () => {
		router.push ( "/games" );
	};

	return (
		<Presentation
			gameList        = { gameList }
			onClickViewMore = { onClickViewMore }
			title           = { title }
		/>
	);
};

export default GameSection;