import dayjs         from "dayjs";
import duration      from "dayjs/plugin/duration";
import { useRouter } from "next/router";

import { useGetLoggedIn, useGetProStatus }    from "_common/hooks/auth";
import { useGetDeviceType, useSetLoginPopup } from "_common/hooks/global";
import { useOpenProModal }                    from "_common/hooks/useOpenProModal";
import { isPageRoute }                        from "_common/utils";
import { STREAM_TIER }                        from "_components/pages/proPage/constants";
import { useGetFinishedAd }                   from "_jotai/rooterPro";

import Presentation from "./presentation";

dayjs.extend ( duration );

const component = ( {
	proExpiring,
	timeLeftInSeconds,
	showTimer = true,
	streamTier = STREAM_TIER.REGULAR_STREAM,
	showWatchAd,
	inProp,
	streamId
} ) => {
	const openProModal  = useOpenProModal ();
	const isProUser     = useGetProStatus ();
	const deviceType    = useGetDeviceType ();
	const finishedAd    = useGetFinishedAd ();
	const router        = useRouter ();
	const isLoggedIn    = useGetLoggedIn ();
	const setLoginPopup = useSetLoginPopup ();

	const isStreamPage = isPageRoute ( {
		router,
		page: "stream"
	} );

	if ( streamTier !== STREAM_TIER.MID_STREAM && streamTier !== STREAM_TIER.PRO_STREAM ) {
		return null;
	}

	function formatTime ( seconds ) {
		const duration = dayjs.duration ( seconds * 1000 );

		return duration.format ( "HH:mm:ss" );
	}

	function onClickProBar ( e ) {

		e.stopPropagation ();
		if ( deviceType === "desktop" ) {
			if ( isStreamPage && document.fullscreenElement ) {
				document?.exitFullscreen ();

				return;
			}

			if ( streamTier === STREAM_TIER.PRO_STREAM ) {
				openProModal ();

				return;
			}

			if ( streamTier === STREAM_TIER.MID_STREAM ) {
				// open ad module here
				if ( !isLoggedIn ) {
					setLoginPopup ( true );

					return;
				}

				showWatchAd ();

				return;
			}

		}

		if ( streamTier === STREAM_TIER.MID_STREAM ) {
			// open ad module here

			if ( !isLoggedIn ) {
				setLoginPopup ( true );

				return;
			}
			showWatchAd ();

			return;
		}

		// mobile flow
		router.push ( "/pro" );
	}

	return (
		<Presentation
			finishedAd        = { finishedAd }
			inProp            = { inProp }
			isProUser         = { isProUser }
			onClickProBar     = { onClickProBar }
			proExpiring       = { proExpiring }
			showTimer         = { showTimer }
			streamId          = { streamId }
			streamTier        = { streamTier }
			timeLeftInSeconds = { formatTime ( timeLeftInSeconds ) }
		/>
	);
};

export default component;
