import { Tooltip } from "@mantine/core";
import React       from "react";

import { useGetDarkMode } from "_common/hooks/global";
import Typography         from "_components/atoms/typography";
import { palette }        from "_styles/theme";

const component = props => {
	const {
		arrow = false,
		title,
		children,
		disable = false,
		placement = "right",
		disableLightMode = false,
		withArrow = true
	} = props;

	const darkMode = useGetDarkMode ();

	return (
		<Tooltip
			arrowSize            = { 8 }
			color                = { darkMode ? palette.darkModeInclinedBlack1 : palette.secondaryWhite }
			disabled             = { disable }
			label                = { (
				<Typography color = { !darkMode ? palette.darkModeInclinedBlack1 : palette.secondaryWhite }
					title            = { title }
					variant          = "body1"
				>
				</Typography>
			) }
			position             = { placement }
			radius               = "md"
			style                = { { boxShadow: palette.boxShadowLight, padding: "6px 10px" } }
			transitionProps      = { { duration: 150, transition: "pop" } }
			withArrow            = { withArrow }
			withinPortal         = { true }
			zIndex               = { 9999999 }
		>
			<div>
				{ children }
			</div>
		</Tooltip>
	);
};

export default component;
