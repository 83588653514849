import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		container: {
			position : "absolute",
			top      : "1rem",
			left     : "1rem",
			zIndex   : "1",
			display  : "flex",
			gap      : "1rem"
		},
		buyProButton: {
			paddingInlineEnd   : "0.5rem",
			paddingInlineStart : "0.8rem",
			paddingBlock       : "0.8rem",
			borderRadius       : "0.8rem",
			background         : theme.other.paletteNew.proGradient
		},
		timerContainer: {
			padding      : "0.5rem 1.5rem",
			background   : theme.other.paletteNew.secondaryBlack50,
			borderRadius : "1rem",
			color        : theme.other.paletteNew.secondaryWhite
		},
		buyProButtonText: {
			fontSize: "1.2rem !important"
		}
	};

} );