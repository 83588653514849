import ProductItem from "_components/organisms/productItem";

import useStyles from "./styles";

const component = ( { shopData, loading } ) => {
	const { classes } = useStyles ();

	return (
		<div className = { classes.desktopGrid }>
			{
				shopData?.map ( ( data, index ) => {
					return (
						<ProductItem
							key     = { index }
							data    = { data }
							loading = { loading }
						/>
					);
				} )
			}
		</div>
	);
};

export default component;