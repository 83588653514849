import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => ( {
	container: {
		margin: "4rem 2rem",

		[rooterMediaQuery.mobile]: {
			margin: "1.4rem 1rem"
		}
	},
	gameCardContainer: {
		display             : "grid",
		gridTemplateColumns : "repeat(auto-fit, 150px)",
		gridTemplateRows    : "1fr",
		height              : "18rem",
		overflow            : "hidden",
		gridGap             : "1.6rem",

		[rooterMediaQuery.tablet]: {
			gridTemplateColumns : "repeat(auto-fit, 140px)",
			justifyContent      : "space-evenly"

		},

		[rooterMediaQuery.mobile]: {
			gridTemplateColumns : "repeat(auto-fit, 75px)",
			gridGap             : "1.5rem",
			height              : "11rem",
			justifyContent      : "space-evenly"

		}
	},
	divider: {
		height          : "5px",
		backgroundColor : theme.other.colors.border.primary
	}
} ) );
