import { atom, useAtomValue, useSetAtom } from "jotai";

export const pipModeAtom = atom ( {} );
export const hoverAnimation = atom ( false );
export const cardMuteState = atom ( true );

export const useSetPipMode = () => {
	return useSetAtom ( pipModeAtom );
};

export const useGetPipMode = () => {
	return useAtomValue ( pipModeAtom );
};

export const useSetHoverAnimation = () => {
	return useSetAtom ( hoverAnimation );
};

export const useGetHoverAnimation = () => {
	return useAtomValue ( hoverAnimation );
};

export const useSetCardMuteState = () => {
	return useSetAtom ( cardMuteState );
};

export const useGetCardMuteState = () => {
	return useAtomValue ( cardMuteState );
};

export const pipModeStatusAtom = atom ( get => get ( pipModeAtom )?.status );

export const useGetPipModeStatus = () => {
	return useAtomValue ( pipModeStatusAtom );
};
