import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage }                from "jotai/utils";

export const userLoggedInAtom = atomWithStorage ( "user-logged-in", false );
export const userProfileDataAtom = atomWithStorage ( "user-profile-data", null, undefined, { getOnInit: true } );
export const userRolesAtom = atom ( [] );

export const useSetUserRoles = () => useSetAtom ( userRolesAtom );
export const useGetUserRoles = () => useAtomValue ( userRolesAtom );

export const useSetLoggedIn = () => {
	return useSetAtom ( userLoggedInAtom );
};

export const useGetLoggedIn = () => {
	return useAtomValue ( userLoggedInAtom );
};

export const useSetProfileData = () => {
	return useSetAtom ( userProfileDataAtom );
};

export const useGetProfileData = () => {
	return useAtomValue ( userProfileDataAtom );
};

export const userProfileIdAtom = atom ( get => get ( userProfileDataAtom )?.id );
export const userProfileGemsAtom = atom ( get => get ( userProfileDataAtom )?.gems );
export const userProfileNameAtom = atom ( get => get ( userProfileDataAtom )?.name );
export const userProfileTotalPointsAtom = atom ( get => get ( userProfileDataAtom )?.totalPoints );

export const userProfileProStatusAtom = atom ( get => {

	// case 1 : user logged out -> return null
	// case 2 : user logged in -> but value hasnt returned from server or api -> return undefined
	// case 3 : user logged in -> user is not pro -> return false
	// case 4 : user logged in -> user is pro -> return true
	const isLoggedIn = get ( userLoggedInAtom );
	const userRoles  = get ( userRolesAtom );

	if ( !isLoggedIn )
		return null;

	if ( !userRoles )
		return undefined;

	return userRoles?.some ( element => {
		if ( element.typeId === 1 ) // typeId 1 is for pro
			return true;
	} );

} );

/* export const userProfileProAtom = atom ( get => {
  const userRoles = get ( userProfileDataAtom )?.userRoles;

  if ( !userRoles )
    return {};

  let details = {};

  userRoles.forEach ( element => {
    if ( element.typeId === 1 ) {
      details = element;
    }
  } );

  return details;
} );

export const useGetProDetails = () => {
  return useAtomValue ( userProfileProAtom );
};
 */
export const useGetProStatus = () => {

	return useAtomValue ( userProfileProStatusAtom );
};

export const useGetProfileId = () => {
	return useAtomValue ( userProfileIdAtom );
};

export const useGetProfileGems = () => {
	return useAtomValue ( userProfileGemsAtom );
};

export const useSetProfileGems = () => {
	const profileData    = useGetProfileData ();
	const setProfileData = useSetProfileData ();

	return value => {
		if ( !value )
			return;

		setProfileData ( { ...profileData, gems: value } );
	};
};

export const useGetProfileName = () => {
	return useAtomValue ( userProfileNameAtom );
};

export const useGetProfileTotalPoints = () => {
	return useAtomValue ( userProfileTotalPointsAtom );
};
