import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage }                from "jotai/utils";

export const DEFAULT_EXPIRY_TIME_MS = 24 * 60 * 60 * 1000;
export const remainingTimeAtom = atomWithStorage ( "remaining_time", null, undefined, { getOnInit: true } );
export const canWatchMoreContentAtom = atom ( true );
export const watchAdAtom = atom ( false );
export const duplicatePlayback = atom ( false );

export const useGetRemainingTime = () => {
	return useAtomValue ( remainingTimeAtom );
};
export const useSetRemainingTime = () => {
	return useSetAtom ( remainingTimeAtom );
};

export const useGetCanWatchMoreContent = () => {
	return useAtomValue ( canWatchMoreContentAtom );
};
export const useSetCanWatchMoreContent = () => {
	return useSetAtom ( canWatchMoreContentAtom );
};

export const useGetDuplicatePlayback = () => {
	return useAtomValue ( duplicatePlayback );
};
export const useSetDuplicatePlayback = () => {
	return useSetAtom ( duplicatePlayback );
};
export const useGetWatchAd = () => {
	return useAtomValue ( watchAdAtom );
};
export const useSetWatchAd = () => {
	return useSetAtom ( watchAdAtom );
};
