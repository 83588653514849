import { AspectRatio, Flex, Skeleton, Stack } from "@mantine/core";

function ProductItemSkeleton () {
	return (
		<Stack m = { 4 }
			maw     = { "50vw" }
			spacing = { 8 }
		>
			<AspectRatio ratio = { 1 / 1 }>
				<Skeleton
					radius = { "md" }
					w      = { "100%" }
				/>
			</AspectRatio>

			<Skeleton h = { "1rem" }
				w          = { "90%" }
			/>

			<Skeleton h = { "1rem" }
				w          = { "60%" }
			/>

			<Flex gap = { 2 }>
				<Skeleton h = { "1rem" }
					w          = { "20%" }
				/>

				<Skeleton h = { "1rem" }
					ml         = { 16 }
					w          = { "20%" }
				/>

				<Skeleton h = { "1rem" }
					ml         = { "auto" }
					w          = { "20%" }
				/>
			</Flex>
		</Stack>
	);
}
export default ProductItemSkeleton;