import { useGetDeviceType } from "_common/hooks/global";
import GameCard             from "_components/molecules/gameCard";
import useStyles            from "_styles/templates/homepage/gameSection";

import Header from "../header";

const GameSection = ( { title, gameList = [], onClickViewMore } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const deviceType = useGetDeviceType ();
	const isMobile   = deviceType === "mobile";

	if ( !gameList?.length )
		return null;

	return (
		<>
			<div className = { classes.container }>
				<Header
					onClickViewMore = { onClickViewMore }
					title           = { title }
				/>

				<div className = { classes.gameCardContainer }>
					{gameList.map ( ( item, key ) => (
						<GameCard
							key  = { key }
							data = { item }
						/>
					)
					) }
				</div>

			</div>

			{
				isMobile ?
					<div className = { classes.divider } /> :
					null
			}
		</>
	);
};

export default GameSection;
