import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => {
	const imageRadius = "1.6rem";

	return {
		productItemWrapper: {
			position      : "relative",
			cursor        : "pointer",
			minWidth      : "18rem",
			display       : "flex",
			flexDirection : "column",
			height        : "100%",
			// alignItems    : "stretch",

			[rooterMediaQuery.mobile]: {
				borderRadius : "1rem",
				padding      : "1rem",
				background   : theme.other.darkMode ? theme.other.colors.dialog.primary : theme.other.paletteNew.secondaryBackgroundGrey,
				boxShadow    : theme.shadows.sm
			}
		},
		productImage: {
			aspectRatio  : "1/1",
			boxShadow    : theme.shadows.sm,
			border       : `1px solid ${ theme.other.colors.darkGray20 }`,
			borderRadius : imageRadius
		},
		imageSkeleton: {
			borderRadius : imageRadius,
			height       : "100%",
			width        : "100%"
		},
		productDetails: {
			alignItems: "center"
		},
		offersIcon: {
		},
		productItemFooter: {
			color: theme.other.colors.successGreen
		},
		imageWrapper: {
			position     : "relative",
			borderRadius : imageRadius,
			aspectRatio  : "1/1"
		},
		wishlistIconWrapper: {
			width       : "1rem",
			aspectRatio : "1/1"
		},
		iconWrapper: {
			bottom         : "5%",
			right          : "5%",
			overflow       : "hidden",
			position       : "absolute",
			gap            : "0.2rem",
			transition     : "all 0.3s",
			aspectRatio    : "1/1",
			borderRadius   : "1rem",
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center"
		},
		productDetailsWrapper: {
			display       : "flex",
			flexDirection : "column",
			gap           : "0.5rem"
		},
		amountWrapper: {
			display    : "flex",
			gap        : "0.4rem",
			alignItems : "baseline"
		}
	};
} );