import { useRouter } from "next/router";

import { useGetLoggedIn }                     from "_common/hooks/auth";
import { useGetDeviceType, useSetLoginPopup } from "_common/hooks/global";

import { useAddQueryParam } from "./useQueryParams";

export function useOpenProModal () {
	const isLoggedIn    = useGetLoggedIn ();
	const setLoginPopup = useSetLoginPopup ();
	const router        = useRouter ();
	const deviceType    = useGetDeviceType ();
	const addQueryParam = useAddQueryParam ();

	return () => {
		if ( !isLoggedIn ) {
			setLoginPopup ( true );

			return;
		}
		if ( deviceType === "mobile" ) {
			router.push ( "/pro" );
		}

		addQueryParam ( { proModal: "true" } );
	};

}
