import { useRouter } from "next/router";

import { storeEvent }    from "_analytics/index";
import { convertToSlug } from "_common/utils";

import Presentation from "./presentation";

const GameCard = ( { data, getFormatedCardData } ) => {

	const router = useRouter ();

	const onClick = () => {
		const slugifyName = convertToSlug ( data.name );

		storeEvent ( "Game_Card", "Clicked", `Game_Card_${ data.name }` );
		router.push ( `/game/${ data.id }/${ slugifyName }` );
	};

	return (
		<Presentation
			data                = { typeof ( getFormatedCardData ) === "function" ? getFormatedCardData ( data ) : data }
			getFormatedCardData = { getFormatedCardData }
			onClick             = { onClick }
		/>
	);
};

export default GameCard;
