import React from "react";

import { useGetDeviceType } from "_common/hooks/global";

import Presentation       from "./presentation";
import PresentationMobile from "./presentation.mobile";

const component = props => {
	const deviceType = useGetDeviceType ();
	const isMobile   = deviceType === "mobile";

	if ( isMobile )
		return <PresentationMobile { ...props } />;

	return (
		<Presentation
			{ ...props }
		/>
	);
};

export default component;